// @ts-nocheck
/* eslint-disable */

  import { buildQueryContext, QueryFormData } from '@superset-ui/core';

  export default function buildQuery(formData: QueryFormData) {
    const { cols: groupby } = formData;
    const { lat, lon, markerName, htmlTemplate, styleTemplate } = formData;

    let columns = []

    if (typeof(lat) === 'object') {
      columns.push(lat[0]);
    } else columns.push(lat);
    
    if (typeof(lon) === 'object') {
      columns.push(lon[0]);
    } else columns.push(lon);

    if (typeof(markerName) === 'object') {
      for (let i=0;i<markerName.length;i++) {
        columns.push(markerName[i]);
      }
    } else columns.push(markerName);

    formData.metrics = [{
      "expressionType": "SQL",
      "sqlExpression": "COUNT(*)",
      "column": null,
      "aggregate": null,
      "isNew": false,
      "hasCustomLabel": false,
      "label": "COUNT(*)",
      "optionName": "metric_8s217s5e0sf_1ce3dl271ub"
    }];


    let ret = buildQueryContext(formData, baseQueryObject => [
      {
        ...baseQueryObject,
        lat,
        lon,
        markerName,
        groupby,
        htmlTemplate,
        styleTemplate
      },
    ]); 

    // Add columns for query here
    ret.queries[0].cols = columns;
    ret.queries[0].columns = columns;

    return ret;
  };
