// @ts-nocheck
/* eslint-disable */ 

import {
  ControlSetItem,
  CustomControlConfig,
  sharedControls,
} from '@superset-ui/chart-controls';
import { t } from '@superset-ui/core';
import React from 'react';
import { CodeEditor } from '../components/CodeEditor/CodeEditor';
import { ControlHeader } from '../components/ControlHeader/controlHeader';
import { debounceFunc } from '../../consts';

interface HandlebarsCustomControlProps {
  value: string;
}

const HandlebarsTemplateControl = (
  props: CustomControlConfig<HandlebarsCustomControlProps>,
) => {
  const val = String(
    props?.value ? props?.value : props?.default ? props?.default : '',
  );

  return (
    <div>
      <ControlHeader>{props.label}</ControlHeader>
      <CodeEditor
        theme="dark"
        value={val}
        onChange={source => {
          debounceFunc(props.onChange, source || '');
        }}
      />
    </div>
  );
};

function validateValidHTML(e) {
  var doc = document.createElement('div');
  doc.innerHTML = e;
  return (doc.innerHTML !== e);
}

export const handlebarsTemplateControlSetItem: ControlSetItem = {
  name: 'htmlTemplate',
  config: {
    ...sharedControls.entity,
    type: HandlebarsTemplateControl,
    label: t('Markernames Template'),
    description: t('Use this HTML Editor to style the Popup marker names.'),
    default: ``,
    isInt: false,
    renderTrigger: true,
    validators: [validateValidHTML],
    mapStateToProps: ({ controls }) => ({
      value: controls?.handlebars_template?.value,
    }),
  },
};
