// @ts-nocheck
/* eslint-disable */


import { debounce } from 'lodash';
import { formatSelectOptions } from '@superset-ui/chart-controls';
import { SLOW_DEBOUNCE, t } from '@superset-ui/core';

export const PAGE_SIZE_OPTIONS = formatSelectOptions<number>([
  [0, t('page_size.all')],
  1,
  2,
  3,
  4,
  5,
  10,
  20,
  50,
  100,
  200,
]);

export const debounceFunc = debounce(
  (func: (val: string) => void, source: string) => func(source),
  SLOW_DEBOUNCE,
);
