/* eslint-disable */

import { ChartProps, TimeseriesDataRecord } from '@superset-ui/core';

export default function transformProps(chartProps: ChartProps) {

  const { width, height, formData, queriesData } = chartProps;
  const { metrics, lat, lon, markerName, htmlTemplate, styleTemplate } = formData;
  const data = queriesData[0].data as TimeseriesDataRecord[];
  //console.log('data in transformporps', data);

  return {
    width,
    height,
    lat,
    lon,
    data,
    metrics,
    markerName,
    htmlTemplate,
    styleTemplate,
  };
}
