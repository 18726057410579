// @ts-nocheck
/* eslint-disable */


import {
  ControlSetItem,
  CustomControlConfig,
  sharedControls,
  InfoTooltipWithTrigger,
} from '@superset-ui/chart-controls';
import { t, useTheme } from '@superset-ui/core';
import React from 'react';
import { CodeEditor } from '../components/CodeEditor/CodeEditor';
import { ControlHeader } from '../components/ControlHeader/controlHeader';
import { debounceFunc } from '../../consts';

interface StyleCustomControlProps {
  value: string;
}

const StyleControl = (props: CustomControlConfig<StyleCustomControlProps>) => {
  const theme = useTheme();

  const defaultValue = props?.value
    ? undefined
    : ``;

  return (
    <div>
      <ControlHeader>
        <div>
          {props.label}
          <InfoTooltipWithTrigger
            iconsStyle={{ marginLeft: theme.gridUnit }}
            tooltip={t('Definiere den Style der CSS Klassen.')}
          />
        </div>
      </ControlHeader>
      <CodeEditor
        theme="dark"
        mode="css"
        value={props.value}
        defaultValue={defaultValue}
        onChange={source => {
          debounceFunc(props.onChange, source || '');
        }}
      />
    </div>
  );
};

export const styleControlSetItem: ControlSetItem = {
  name: 'styleTemplate',
  config: {
    ...sharedControls.entity,
    type: StyleControl,
    label: t('CSS Style Editor'),
    description: t('CSS Definitionen'),
    isInt: false,
    renderTrigger: true,
    validators: [],
    mapStateToProps: ({ controls }) => ({
      value: controls?.handlebars_template?.value,
    }),
  },
};
