// @ts-nocheck
/* eslint-disable */
/* tslint:disable */

import { ChartProps, TimeseriesDataRecord } from '@superset-ui/core';

export default function transformProps(chartProps: ChartProps) {

  const { width, height, formData, queriesData, datasource } = chartProps;
  
  let mapName = datasource.datasourceName;
  const { cols,
    category,
    colorPickerStart,
    colorPickerMiddle,
    colorPickerEnd,
    hasMiddleColor,
    numberGradient,
    borderWeight,
    borderColor,
    backgroundColor,
    borderType,
    colorBerlinDistricts,
    borderWeightDistricts,
    legendType,
    useOwnLimits,
    minimalValue,
    maximumValue,
    legendTitel,
    areaNoValues,
    backgroundColorNoValues,
    valueEmptyData,
    numberFormat,
    showCheckboxStreetMap,
    lineCharts,
    opacityValue,
    numberFormatLegend,
    showStreepMap } = formData;
  const data = queriesData[0].data as TimeseriesDataRecord[];

  return {
    width,
    height,
    data,
    // and now your control data, manipulated as needed, and passed through as props!
    cols,
    category,
    colorPickerStart,
    colorPickerMiddle,
    colorPickerEnd,
    hasMiddleColor,
    numberGradient,
    borderWeight,
    borderColor,
    backgroundColor,
    mapName,
    borderType,
    colorBerlinDistricts, 
    borderWeightDistricts,
    useOwnLimits, 
    minimalValue, 
    maximumValue,
    legendType,
    areaNoValues, 
    backgroundColorNoValues,
    valueEmptyData,
    numberFormat,
    legendTitel,
    lineCharts,
    showStreepMap,
    numberFormatLegend,
    showCheckboxStreetMap,
    opacityValue
  };
}
