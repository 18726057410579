// @ts-nocheck
/* eslint-disable */

import { t, validateNonEmpty } from '@superset-ui/core';
import { ControlPanelConfig, 
  sections, sharedControls, 
  D3_FORMAT_OPTIONS,
  D3_FORMAT_DOCS, } from '@superset-ui/chart-controls';

const PRIMARY_COLOR_START = { r: 0, g: 255, b: 255, a: 1 };
const PRIMARY_COLOR_MIDDLE = { r: 128, g: 128, b: 128, a: 1 };
const PRIMARY_COLOR_END = { r: 255, g: 0, b: 0, a: 1 };
const PRIMARY_COLOR_BORDER = { r: 0, g: 0, b: 0, a: 1 };
const PRIMARY_COLOR_BACKGROUND = { r: 170, g: 170, b: 170, a: 1 };
const PRIMARY_COLOR_DISTRICTS = { r: 32, g: 32, b: 255, a: 1 };
const PRIMARY_COLOR_OTHER_DISTRICTS = { r: 255, g: 255, b: 255, a: 1 };

const locations = [
  ['Ber', 'Berlin'],
  ['Mi', 'Mitte'],
  ['FK', 'Friedrichshain-Kreuzberg'],
  ['Pa', 'Pankow'],
  ['CW', 'Charlottenburg-Wilmersdorf'],
  ['Sp', 'Spandau'],
  ['SZ', 'Steglitz-Zehlendorf'],
  ['TS', 'Tempelhof-Schoeneberg'],
  ['Neu', 'Neukoelln'],
  ['TK', 'Treptow-Koepenick'],
  ['MH', 'Marzahn-Hellersdorf'],
  ['Li', 'Lichtenberg'],
  ['Re', 'Reinickendorf'],
  ['KE', 'Keine']
];

const category = [
  ['B', 'Berliner Bezirke'],
  ['PLZ', 'Postleitzahl Bereiche'],
  ['LOR_PLANNING_2020', 'LOR Planungsräume (vor 2021)'],
  ['LOR_PLANNING_2021', 'LOR Planungsräume (nach 2021)'],
  ['LOR_DISTRICT_2020', 'LOR Bezirksregionen (vor 2021)'],
  ['LOR_DISTRICT_2021', 'LOR Bezirksregionen (nach 2021)'],
  ['LOR_FORECAST_2020', 'LOR Prognoseräume (vor 2021)'],
  ['LOR_FORECAST_2021', 'LOR Prognoseräume (nach 2021)']
]

const fillColorPicker = {
  type: 'ColorPickerControl',
  renderTrigger: true,
};

const opacityRange = [
  [0.1, "0.1"],
  [0.2, "0.2"],
  [0.3, "0.3"],
  [0.4, "0.4"],
  [0.5, "0.5"],
  [0.6, "0.6"],
  [0.7, "0.7"],
  [0.8, "0.8"],
  [0.9, "0.9"],
  [1.0, "1.0"]
];

const colorSpectrum = [
  [4, 4],
  [5, 5],
  [6, 6],
  [7, 7],
  [8, 8],
  [9, 9],
  [10, 10],
  [11, 11],
  [12, 12],
  [13, 13],
  [14, 14],
  [15, 15],
  [16, 16],
]

const weightRange = [
  [0.25, "0.25"],
  [0.5, "0.5"],
  [0.75, "0.75"],
  [1, "1"],
  [1.5, "1.5"],
  [2.25, "2.25"],
  [4.5, "4.5"],
  [6, "6"],
]

const outputFormat = [
  ['A', 'Absolute Zahlen'],
  ['%', 'Prozentangaben'],
]

const legendTypes = [
  ['K', 'Keine'],
  ['B', 'Blocklegende'],
  ['P', 'Horizontale Legende']
]

const borderTypes = [
  ['K', 'Keine'],
  ['B', 'Berliner Bezirke'],
  ['PLZ', 'Postleitzahlen'],
  ['LOR_PLANNING_2020', 'LOR Planungsräume (vor 2021)'],
  ['LOR_PLANNING_2021', 'LOR Planungsräume (nach 2021)'],
  ['LOR_DISTRICT_2020', 'LOR Bezirksregionen (vor 2021)'],
  ['LOR_DISTRICT_2021', 'LOR Bezirksregionen (nach 2021)'],
  ['LOR_FORECAST_2020', 'LOR Prognoseräume (vor 2021)'],
  ['LOR_FORECAST_2021', 'LOR Prognoseräume (nach 2021)']
]

const AreaNoValuesChoices = [
  ['NORMAL', 'Keine Werte vorhanden'],
  ['OTHER', 'Andere Werte eingeben']
]

const config: ControlPanelConfig = {
  controlPanelSections: [
    sections.legacyTimeseriesTime,
    {
      label: t('Query'),
      expanded: true,
      controlSetRows: [
        [
          {
            name: 'cols',
            config: {
              ...sharedControls.groupby,
              label: t('Series'),
              description: t('Spalten auswählen, nach denen aggregiert wird.'),
              validators: [validateNonEmpty],
            },
          },
        ],
        [
          {
            name: 'metrics',
            config: {
              ...sharedControls.metrics,
              label: t('Metrik'),
              validators: [validateNonEmpty],
            },
          },
        ],
        [
          {
            name: 'category',
            config: {
              type: 'SelectControl',
              label: t('Gebietskategorie'),
              description: t('Wähle eine Gebietskategorie aus'),
              default: 'B',
              choices: category,
              renderTrigger: true,
            },
          },
        ],
        ['adhoc_filters'],
        [
          {
            name: 'row_limit',
            config: sharedControls.row_limit,
          },
        ],
      ],
    },
    {
      label: t('Karte importieren'),
      expanded: true,
      controlSetRows: [
        [
          {
            name: 'line_charts',
            config: {
              type: 'SelectAsyncControl',
              multi: true,
              label: t('Karte auswählen'),
              default: [],
              description: t('Welche karte auswählen'),
              dataEndpoint:
                '/sliceasync/api/read?_flt_0_viz_type=leaflet',
              placeholder: t('Select charts'),
              onAsyncErrorMessage: t('Error while fetching charts'),
              mutator: (data?: Data) => {
                if (!data || !data.result) {
                  return [];
                }
                return data.result.map(o => ({
                  value: o.id,
                  label: o.slice_name,
                }));
              },
            },
          },
        ],
      ]
    },
    {
      label: t('Farbe, Berandung und Formatierung'),
      expanded: true,
      controlSetRows: [
        [
          {
            name: 'colorPickerStart',
            config: {
              label: t('Kleinster Wert'),
              description: t('Startwert des Farbgradienten'),
              default: PRIMARY_COLOR_START,
              ...fillColorPicker,
            },
          },
          {
            name: 'colorPickerEnd',
            config: {
              label: t('Größter Wert'),
              description: t('Startwert des Farbgradienten'),
              default: PRIMARY_COLOR_END,
              ...fillColorPicker,
            },
          },
        ],
        [
          {
            name: 'hasMiddleColor',
            config: {
              type: 'CheckboxControl',
              label: t('Mittleren Farbwert bestimmen?'),
              renderTrigger: true,
              default: false,
              description: t('Kartenuntergrund anzeigen?'),
            },
          },
          {
            name: 'colorPickerMiddle',
            config: {
              label: t('Mittlerer Wert'),
              description: t('Mittlerer Wert des Farbgradienten'),
              default: PRIMARY_COLOR_MIDDLE,
              ...fillColorPicker,
            },
          },
        ],
        [
          {
            name: 'borderColor',
            config: {
              label: t('Farbe Berandung'),
              description: t('Startwert des Farbgradienten'),
              default: PRIMARY_COLOR_BORDER,
              ...fillColorPicker,
            },
          },
          {
            name: 'backgroundColor',
            config: {
              label: t('Farbe vom Hintergrund'),
              description: t('Startwert des Farbgradienten'),
              default: PRIMARY_COLOR_BACKGROUND,
              ...fillColorPicker,
            },
          },
        ],
        [
          {
            name: 'numberGradient',
            config: {
              type: 'SelectControl',
              label: t('Anzahl Farbspektren'),
              description: t('Wie granular soll die Farbaufteilung gewählt werden?'),
              default: 8,
              choices: colorSpectrum,
              renderTrigger: true,
            },
          },
        ],
        [
          {
            name: 'borderWeight',
            config: {
              type: 'SelectControl',
              label: t('Dicke Begrenzung'),
              description: t('Wie dick sollen die Begrenzungslinien sein?'),
              default: 1,
              choices: weightRange,
              renderTrigger: true,
            },
          },
        ],
        [
          {
            name: 'areaNoValues',
            config: {
              type: 'SelectControl',
              label: t('Andere Farbe für Bereiche ohne Daten'),
              renderTrigger: true,
              default: 'NORMAL',
              choices: AreaNoValuesChoices,
              description: t('Sollen Bereiche ohne Werte andere Einfärbungen bekommen?'),
            },
          },
        ],
        [
          {
            name: 'backgroundColorNoValues',
            config: {
              label: t('Farbe vom Hintergrund ohne Daten'),
              description: t('Farbe vom Hintergrund für Bereiche ohne Daten'),
              default: PRIMARY_COLOR_OTHER_DISTRICTS,
              ...fillColorPicker,
            },
          },
          {
            name: 'valueEmptyData',
            config: {
              type: 'TextControl',
              isInt: false,
              default: '0',
              renderTrigger: true,
              label: t('Werte für Bereiche ohne DateN'),
              description: t('Welchen Wert haben Bereiche ohne Daten?'),
            },
          }
        ],
        [
          {
            name: 'numberFormat',
            config: {
              type: 'SelectControl',
              freeForm: true,
              label: t('Zahlenformatierung'),
              renderTrigger: true,
              default: 'SMART_NUMBER',
              choices: D3_FORMAT_OPTIONS,
              description: D3_FORMAT_DOCS,
            },
          },
        ],
      ]
    },
    {
      label: t('Skalengrenzen'),
      expanded: true,
      controlSetRows: [
        [
          {
            name: 'useOwnLimits',
            config: {
              type: 'CheckboxControl',
              label: t('Andere Wertebereiche'),
              renderTrigger: true,
              default: false,
              description: t('Sollen eigene Wertebereiche gesetzt werden?'),
            },
          },
        ],
        [
          {
            name: 'minimalValue',
            config: {
              type: 'TextControl',
              isInt: false,
              default: 0.1,
              renderTrigger: true,
              label: t('Minimaler Wert'),
              description: t('Minimaler Wert im Legenden-Maßstab'),
            },
          },
          {
            name: 'maximumValue',
            config: {
              type: 'TextControl',
              isInt: false,
              default: 1,
              renderTrigger: true,
              label: t('Maximaler Wert'),
              description: t('Maximaler Wert im Legenden-Maßstab'),
            },
          }
        ],
      ]
    },
    {
      label: t('Legende'),
      expanded: true,
      controlSetRows: [
        [
          {
            name: 'legendType',
            config: {
              type: 'SelectControl',
              label: t('Typ Legende'),
              description: t('Welcher Legendentyp soll angezeigt werden?'),
              default: 'K',
              choices: legendTypes,
              renderTrigger: true,
            },
          },
        ],
        [
          {
            name: 'legendTitel',
            config: {
              type: 'TextControl',
              isInt: false,
              default: 'Titel Legende',
              renderTrigger: true,
              label: t('Titel der Legende'),
              description: t('Welchen Titel hat die Legende'),
            },
          }
        ],
        [
          {
            name: 'numberFormatLegend',
            config: {
              type: 'SelectControl',
              freeForm: true,
              label: t('Formatierung Legende'),
              renderTrigger: true,
              default: 'SMART_NUMBER',
              choices: D3_FORMAT_OPTIONS,
              description: D3_FORMAT_DOCS,
            },
          },
        ],
      ],
    },
    {
      label: t('Straßenkarte und Durchsichtigkeit'),
      expanded: true,
      controlSetRows: [
        [
          {
            name: 'showCheckboxStreetMap',
            config: {
              type: 'CheckboxControl',
              label: t('Straßenkarte Schalter anzeigen?'),
              renderTrigger: true,
              default: true,
              description: t('Schalter zum Umstellen der Kartendarstellung anzeigen?'),
            },
          },
        ],
        [
          {
            name: 'showStreepMap',
            config: {
              type: 'CheckboxControl',
              label: t('Straßenkarte anzeigen?'),
              renderTrigger: true,
              default: true,
              description: t('Kartenuntergrund anzeigen?'),
            },
          },
        ],
        [
          {
            name: 'opacityValue',
            config: {
              type: 'SelectControl',
              label: t('Durchsichtigkeit der Flächen'),
              description: t('Wie durchsichtig sollen die Farbflächen sein?'),
              default: 1,
              choices: opacityRange,
              renderTrigger: true,
            },
          },
        ],
      ]
    },
    {
      label: t('Weitere Begrenzung'),
      expanded: true,
      controlSetRows: [
        [
          {
            name: 'borderType',
            config: {
              type: 'SelectControl',
              label: t('Welche Grenzen'),
              description: t('Welche Grenzen sollen eingezeichnet werden?'),
              default: 'K',
              choices: borderTypes,
              renderTrigger: true,
            },
          },
          {
            name: 'colorBerlinDistricts',
            config: {
              label: t('Farbwert Bezirksgrenzen'),
              description: t('Farbwert der Berlingrenzen'),
              default: PRIMARY_COLOR_DISTRICTS,
              ...fillColorPicker
            },
          },
        ],
        [
          {
            name: 'borderWeightDistricts',
            config: {
              type: 'SelectControl',
              label: t('Dicke Begrenzung'),
              description: t('Wie dick sollen die Begrenzungslinien sein?'),
              default: 1.5,
              choices: weightRange,
              renderTrigger: true,
            },
          },
        ],
      ]
    },
  ],
};

export default config;
