// @ts-nocheck
/* eslint-disable */

import { buildQueryContext, QueryFormData } from '@superset-ui/core';

export default function buildQuery(formData: QueryFormData) {
  console.log('formdata in query', formData);
  const { cols: groupby } = formData;
  const { cols, 
    category, 
    marks, 
    colorPickerStart, 
    colorPickerMiddle,
    colorPickerEnd,
    hasMiddleColor,
     numberGradient,
     borderColor,
     backgroundColor,
     borderWeight,
     borderType,
     mapName,
     colorBerlinDistricts,
     borderWeightDistricts,
     legendType,
     useOwnLimits,
     minimalValue,
     maximumValue,
     legendTitel,
     areaNoValues,
     backgroundColorNoValues,
     valueEmptyData,
     numberFormat,
     showCheckboxStreetMap,
     line_charts,
     showStreepMap,
     numberFormatLegend,
     opacityValue } = formData;
  return buildQueryContext(formData, baseQueryObject => [
    {
      ...baseQueryObject,
      cols,
      category,
      colorPickerStart,
      colorPickerMiddle,
      colorPickerEnd,
      hasMiddleColor,
      numberGradient,
      borderWeight,
      borderColor,
      backgroundColor,
      borderType,
      mapName,
      colorBerlinDistricts,
      borderWeightDistricts,
      legendType,
      useOwnLimits, 
      minimalValue,
      maximumValue,
      legendTitel,
      areaNoValues, 
      backgroundColorNoValues,
      valueEmptyData,
      numberFormat,
      showCheckboxStreetMap,
      line_charts,
      showStreepMap,
      opacityValue,
      numberFormatLegend,
      groupby,
    },
  ]);
}
