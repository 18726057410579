/* eslint-disable */

import { t, ChartMetadata, ChartPlugin } from '@superset-ui/core';
import buildQuery from './buildQuery';
import controlPanel from './controlPanel';
import transformProps from './transformProps';
import thumbnail from '../images/thumbnail.png';

export default class LeafletChartPlugin extends ChartPlugin {

  constructor() {
    const metadata = new ChartMetadata({
      description: 'Leaflet Plugin zur Visualisierung von Daten der Berliner Verwaltung',
      name: t('Analysegebiete im Raum Berlin'),
      category: t('Map'),
      tags: [t('Geo'), t('Leaflet'), t('Berlin')],
      thumbnail,
    });

    super({
      buildQuery,
      controlPanel,
      loadChart: () => import('../Leaflet'),
      metadata,
      transformProps,
    });
  }
}
